.MuiDropzoneArea-root {
    min-height: 120px !important;
    margin-top: 50px !important;
    width:80% !important;
    border:2px dashed #3971FF !important;
    background: #F8FAFF !important;
    height:100px
   
}

.MuiDropzoneArea-textContainer{
    color:lightgray !important;
    font-size: 18px !important;
    margin:9% !important;
}

.MuiDropzoneArea-icon {
    display: none !important;
}

.MuiDropzoneArea-text {
    margin-top: -24px !important;
    font-size: 18px !important;
}

.MuiGrid-container {
    width: 80% !important;
}