.eom-offcanvas {
    position: absolute !important;
}
.eom-hamburger-wrap:hover{
    border:3px solid #333 !important
}


.eom-hamburger span:hover{
    border-top: 3px solid #333 !important
}

.logouts {
    cursor:pointer;
    font-size: 20px;
}

.ohio {
    font-size: 26px;
}